import "./App.css";
import mainImage from "./assets/_M_N7399.jpg";
import secondImage from "./assets/QAF06886.JPG";
import sixthImage from "./assets/QAF07057.JPG";
import { FaInstagram } from "react-icons/fa";
import logo from "./assets/logo.jpg";
function App() {
  return (
    // <>
    //   <meta charSet="UTF-8" />
    //   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    //   <title>فطر الريف</title>
    //   <link
    //     href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap"
    //     rel="stylesheet"
    //   />
    //   <header className="bg-[#d89241] text-white py-4 text-center">
    //     <div className="container mx-auto">
    //       <h1 className="text-4xl font-serif">فطر الريف</h1>
    //     </div>
    //   </header>

    //   <section
    //     id="showcase"
    //     className="flex flex-col lg:flex-row justify-between items-stretch bg-white py-10">
    //     <div className="flex flex-col justify-center flex-1 p-4">
    //       <h2 className="text-2xl mb-4">نزرع بطريقة مستدامة وبمصادر عضوية</h2>
    //       <p className="text-lg mb-4 ml-24">
    //         في فطر الريف، نلتزم بممارسات الزراعة المستدامة والعضوية. تنمو فطرنا
    //         بدون استخدام المواد الكيميائية الضارة، مما يضمن لك منتجات طازجة
    //         وغنية بالعناصر الغذائية.
    //       </p>
    //     </div>
    //     <div className="flex-1 p-10">
    //       <img
    //         className="w-full h-full object-cover rounded-lg"
    //         src={mainImage}
    //         alt="فطر طازج"
    //       />
    //     </div>
    //   </section>

    //   <section id="features" className="bg-gray-100 py-10">
    //     <div className="container mx-auto flex flex-wrap justify-around">
    //       <div className="feature-box text-center p-6 rounded-lg w-full lg:w-1/3">
    //         <img
    //           className="mx-auto  rounded-lg"
    //           src={secondImage}
    //           alt="100% عضوي"
    //         />
    //         <h3 className="text-lg font-semibold mt-4 ">100% عضوي</h3>
    //         <p>
    //           فطرنا يزرع باستخدام أساليب عضوية فقط، خالٍ من المبيدات والمواد
    //           الكيميائية.
    //         </p>
    //       </div>

    //       {/* <div className="feature-box text-center p-4 w-full lg:w-1/3">
    //         <img className="mx-auto" src={fifthImage} alt="زراعة مستدامة" />
    //         <h3 className="text-lg font-semibold mt-4">زراعة مستدامة</h3>
    //         <p>نستخدم ممارسات صديقة للبيئة للحد من تأثيرنا على البيئة.</p>
    //       </div> */}

    //       <div className="feature-box text-center p-6 rounded-lg w-full lg:w-1/3">
    //         <img
    //           className="mx-auto rounded-lg"
    //           src={sixthImage}
    //           alt="طازج يوميًا"
    //         />
    //         <h3 className="text-lg font-semibold mt-4">طازج يوميًا</h3>
    //         <p>يحصد يوميًا لضمان نضارة ونكهة فائقة في كل قضمة.</p>
    //       </div>
    //     </div>
    //   </section>
    //   {/* <section id="features">
    //     <div class="container">
    //         <div class="features-container">
    //             <div class="feature-box">
    //                 <img src="/api/placeholder/90/90" alt="Wide Variety">
    //                 <h3>Wide Variety</h3>
    //                 <p>Choose from our selection of shiitake, oyster, portobello, and more!</p>
    //             </div>
    //             <div class="feature-box">
    //                 <img src="/api/placeholder/90/90" alt="100% Organic">
    //                 <h3>100% Organic</h3>
    //                 <p>Our mushrooms are grown using only organic methods, free from pesticides and chemicals.</p>
    //             </div>
    //             <div class="feature-box">
    //                 <img src="/api/placeholder/90/90" alt="Nutrient-Rich">
    //                 <h3>Nutrient-Rich</h3>
    //                 <p>Packed with vitamins, minerals, and antioxidants for your health and wellness.</p>
    //             </div>
    //             <div class="feature-box">
    //                 <img src="/api/placeholder/90/90" alt="Sustainable Farming">
    //                 <h3>Sustainable Farming</h3>
    //                 <p>We use eco-friendly practices to minimize our environmental impact.</p>
    //             </div>
    //             <div class="feature-box">
    //                 <img src="/api/placeholder/90/90" alt="Fresh Daily">
    //                 <h3>Fresh Daily</h3>
    //                 <p>Harvested daily to ensure maximum freshness and flavor in every bite.</p>
    //             </div>
    //             <div class="feature-box">
    //                 <img src="/api/placeholder/90/90" alt="Local Sourced">
    //                 <h3>Locally Sourced</h3>
    //                 <p>Support your local community with our farm-to-table mushrooms.</p>
    //             </div>
    //         </div>
    //     </div>
    // </section> */}
    //   <section
    //     id="map-contact"
    //     className="py-10 flex flex-col justify-center items-center bg-white">
    //     <div className="container mx-auto text-center flex flex-col w-full p-4 justify-center items-center">
    //       <h3 className="text-2xl p-4">تواصل معنا</h3>
    //       <a
    //         href="https://www.instagram.com/alreef__mushroom" // Replace with your Instagram profile link
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         className="p-4">
    //         <FaInstagram size={60} /> {/* Instagram icon */}
    //       </a>
    //     </div>
    //   </section>

    //   <footer className="bg-[#d89241] text-white py-4 text-center">
    //     <p>فطر الريف © 2024</p>
    //   </footer>
    // </>
    <div dir="rtl" className="min-h-screen bg-[#FFA600] text-white font-arabic">
      <div className="bg-white h-24">
        <a href="/" className="w-min h-min">
          <button className="w-auto h-24">
            <img className="w-auto h-24" src={logo} />
          </button>
        </a>
      </div>
      <header className="py-16 text-center">
        <h1 className="text-5xl font-bold mb-4">فطر الريف</h1>
        <p className="text-2xl max-w-3xl mx-auto">
          مشروع زراعي يتخصّص في زراعة وإنتاج وحصاد وتخزين وبيع الفطر بمختلف
          أنواعه إلى السوق كمنتج محلي على مدار أفصُل السنة.
        </p>
      </header>

      <main className="container mx-auto px-4 space-y-16 mb-16">
        <div className="bg-white/10 backdrop-blur-md overflow-hidden rounded-lg shadow-lg">
          <div className="p-0 flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 p-8 ">
              <h2 className="text-3xl font-bold mb-4">التزامنا بالاستدامة</h2>
              <p className="text-white/80 text-lg">
                في فطر الريف، نلتزم بممارسات الزراعة المستدامة والعضوية. تنمو
                فطرنا بدون استخدام المواد الكيميائية الضارة، مما يضمن لك منتجات
                طازجة وغنية بالعناصر الغذائية.
              </p>
            </div>
            <div className="w-full md:w-1/2 bg-white/5 min-h-[200px] flex items-center justify-center">
              <img src={mainImage} />
            </div>
          </div>
        </div>

        <div className="bg-white/10 backdrop-blur-md overflow-hidden rounded-lg shadow-lg">
          <div className="p-0 flex flex-col md:flex-row-reverse">
            <div className="w-full md:w-1/2 p-8">
              <h2 className="text-3xl font-bold mb-4">طرق الزراعة العضوية</h2>
              <p className="text-white/80 text-lg">
                نستخدم أحدث تقنيات الزراعة العضوية لضمان جودة منتجاتنا. نهتم بكل
                تفاصيل عملية الإنتاج، من اختيار البذور إلى طرق الحصاد، لنقدم لكم
                أفضل أنواع الفطر.
              </p>
            </div>
            <div className="w-full md:w-1/2 bg-white/5 min-h-[200px] flex items-center justify-center">
              <img src={secondImage} />
            </div>
          </div>
        </div>

        <div className="bg-white/10 backdrop-blur-md overflow-hidden rounded-lg shadow-lg">
          <div className="p-0 flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 p-8">
              <h2 className="text-3xl font-bold mb-4">منتجاتنا الطازجة</h2>
              <p className="text-white/80 text-lg">
                نقدم مجموعة متنوعة من الفطر الطازج والعضوي. من فطر عيش الغراب
                إلى الفطر الملكي، كل منتجاتنا تضمن لك تجربة طعام صحية ولذيذة.
              </p>
            </div>
            <div className="w-full md:w-1/2 bg-white/5 min-h-[200px] flex items-center justify-center">
              <img src={sixthImage} />
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-white/10 backdrop-blur-md py-12 text-center">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl font-bold mb-4">تواصل معنا</h2>
          <p className="mb-8 max-w-2xl mx-auto flex justify-center text-white/80">
            لمزيد من المعلومات حول منتجاتنا أو لطلب الفطر الطازج، يرجى التواصل
            معنا عبر الوسائل التالية.
          </p>
          <div className="flex justify-center space-x-4 rtl:space-x-reverse">
            <a href="https://www.instagram.com/alreef__mushroom">
              <button className="px-4 py-2 border border-white text-white rounded hover:bg-white/20 transition-colors">
                <FaInstagram className="m-2" size={20} />
              </button>
            </a>
            {/* <button className="px-4 py-2 border border-white text-white rounded hover:bg-white/20 transition-colors">
              موقعنا
            </button>
            <button className="px-4 py-2 border border-white text-white rounded hover:bg-white/20 transition-colors">
              طلب الآن
            </button> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
